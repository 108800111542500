// components/Footer.js
import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-yellow-500 py-16">
      <div className="max-w-7xl mx-auto px-8">
        <div className="text-center mb-8">
          <h3 className="text-3xl font-bold mb-4">Stay Updated with Our Newsletter</h3>
          <p className="text-xl mb-6">Sign up for exclusive offers and news.</p>
        </div>
        <div className="flex justify-center">
          <form className="flex items-center w-full max-w-md bg-yellow-500 p-4 rounded-lg shadow-lg">
            <input
              type="email"
              placeholder="Enter your email"
              className="flex-1 p-3 rounded-l-lg border-2 border-yellow-500 outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <button
              type="submit"
              className="bg-yellow-600 text-black font-bold px-6 py-3 rounded-r-lg hover:bg-yellow-700 transition duration-300"
            >
              Subscribe
            </button>
          </form>
        </div>
        <div className="text-center mt-8">
          <p className="text-lg">© 2024 PhantomCards Shop. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
