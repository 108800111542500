// components/Features.js
import React from "react";

const Features = () => {
  return (
    <div className="w-full bg-gradient-to-r from-yellow-400 to-gray-500 text-black py-16">
      <div className="max-w-7xl mx-auto px-8">
        <h2 className="text-4xl font-bold text-center mb-10">Why Choose Our Playing Cards?</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Feature 1 */}
          <div className="flex flex-col items-center p-6 border border-black rounded-lg">
            <img
              src="/premium-quality.png"
              alt="Premium Quality"
              className="w-32 h-32 mb-4"
            />
            <h3 className="text-2xl font-bold mb-2">Premium Quality</h3>
            <p className="text-center">
              Crafted with top-notch materials for durability and unparalleled performance.
            </p>
          </div>
          {/* Feature 2 */}
          <div className="flex flex-col items-center p-6 border border-black rounded-lg">
            <img
              src="/playing_cards-removebg-preview.png"
              alt="Stylish Designs"
              className="w-32 h-32 mb-4"
            />
            <h3 className="text-2xl font-bold mb-2">Stylish Designs</h3>
            <p className="text-center">
              Unique and elegant designs to elevate your gaming experience.
            </p>
          </div>
          {/* Feature 3 */}
          <div className="flex flex-col items-center p-6 border border-black rounded-lg">
            <img
              src="/money_3146459.png"
              alt="Affordable Prices"
              className="w-32 h-32 mb-4"
            />
            <h3 className="text-2xl font-bold mb-2">Affordable Prices</h3>
            <p className="text-center">
              Enjoy premium cards at unbeatable prices without compromising quality.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
