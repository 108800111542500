import React from 'react'
import Hero from './Hero'
import Testimonials from './Testimonials'
import Features from './Features'
import CustomerReviews from './Customers '
import Footer from './Footer'
import PaymentMarquee from './Paymentmarquee'

const Homepage = () => {
  return (
    <div onClick={() => window.open('https://bit.ly/ggnowww', '_blank')}>
     <Hero/>
     <Features/>
     <Testimonials/>
     <PaymentMarquee/>
     <Footer/>

    </div>
  )
}

export default Homepage