// components/PaymentMarquee.js
import React from "react";
import Marquee from "react-fast-marquee";

const PaymentMarquee = () => {
  return (
    <div className="w-full bg-black py-6">
      <div className="max-w-9xl mx-auto ">
        <div className="flex justify-center items-center">
          <Marquee
            direction="left"
            speed={50} 
            minScale={0.8}
            className="payment-marquee"
          >
            <div className="flex space-x-16">
              {/* Payment Icons */}
              <img
                src="https://d28wu8o6itv89t.cloudfront.net/images/Visadebitcardpng-1599584312349.png"
                alt="Visa"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://e7.pngegg.com/pngimages/212/797/png-clipart-mastercard-logo-credit-card-maestro-payment-card-mastercard-text-orange-thumbnail.png"
                alt="Mastercard"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://images.ctfassets.net/drk57q8lctrm/21FLkQ2lbOCWynXsDZvXO5/485a163f199ef7749b914e54d4dc3335/paypal-logo.webp"
                alt="PayPal"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://static.businessworld.in/American-Express-Color_20241008185737_original_image_48.webp"
                alt="American Express"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd2BfnD3tPV2mSrVqw50ac7ypFfdWU2z3ThQ&s"
                alt="Discover"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              {/* More Payment Icons */}
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVnsJ3xwmvdf0WjUqC5nDg3Vn5JdXc9u_Dzw&s"
                alt="Skrill"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://e7.pngegg.com/pngimages/212/797/png-clipart-mastercard-logo-credit-card-maestro-payment-card-mastercard-text-orange-thumbnail.png"
                alt="Mastercard"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://lh3.googleusercontent.com/zoVYCRh30-KpXjqMNNr0Bavwf2DPA3tae6ZMrMGw7BPMPOlyj6vN7ehxQiUF6Kv_U9wPvNMk4MBtBPapjGOVuqMaa6f5VaUVTPc0eg=s0"
                alt="Google Pay"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://vikwp.com/images/plugins/stripe.png"
                alt="Stripe"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://static.toiimg.com/thumb/resizemode-4,width-1280,height-720,msid-107434885/107434885.jpg"
                alt="Amazon Pay"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7bhEPscvF04kYc6DFv_ZWNNK93T9iczAQ6Q&s"
                alt="Payoneer"
                className="md:w-44 md:h-20 w-10 h-7"
              />
              <img
                src="https://our-beijing.com/wp-content/uploads/2024/02/Screenshot-2024-02-26-at-8.01.08-PM.png"
                alt="Alipay"
                className="md:w-44 md:h-20 w-10 h-7"
              />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default PaymentMarquee;
