// components/Testimonials.js
import React from "react";

const Testimonials = () => {
  return (
    <div className="w-full bg-black text-yellow-500 py-16">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-10">
          What Our Customers Say
        </h2>
        <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {/* Testimonial 1 */}
          <div className="bg-yellow-500 p-6 rounded-xl shadow-lg transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-2xl">
            <div className="flex items-center mb-4">
              <img
                src="https://images.pexels.com/photos/938639/pexels-photo-938639.jpeg?cs=srgb&dl=pexels-ravi-k-301762-938639.jpg&fm=jpg"
                alt="John Doe"
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <p className="font-bold text-black">Rohit</p>
                <p className="text-black">Regular Customer</p>
              </div>
            </div>
            <p className="text-xl mb-4 text-black drop-shadow-lg">
              "These playing cards are absolutely amazing! The quality is
              fantastic and the designs are so unique. I will definitely buy
              again."
            </p>
          </div>
          {/* Testimonial 2 */}
          <div className="bg-yellow-500 p-6 rounded-xl shadow-lg transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-2xl">
            <div className="flex items-center mb-4">
              <img
                src="https://thumbs.dreamstime.com/b/indian-man-young-good-looking-people-smiling-standing-isolated-white-background-31400054.jpg"
                alt="Jane Smith"
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <p className="font-bold text-black">Pankaj Shrivastav</p>
                <p className="text-black">Verified Buyer</p>
              </div>
            </div>
            <p className="text-xl mb-4 text-black drop-shadow-lg">
              "Great value for money. The cards are durable, and the print is
              sharp and beautiful. Highly recommend!"
            </p>
          </div>
          {/* Testimonial 3 */}
          <div className="bg-yellow-500 p-6 rounded-xl shadow-lg transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-2xl">
            <div className="flex items-center mb-4">
              <img
                src="https://img.freepik.com/free-photo/smiley-man-posing-medium-shot_23-2149915893.jpg?semt=ais_hybrid"
                alt="Mark Johnson"
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <p className="font-bold text-black">Anil Joshi</p>
                <p className="text-black">Happy Customer</p>
              </div>
            </div>
            <p className="text-xl mb-4 text-black drop-shadow-lg">
              "I am very impressed with the cards I purchased. Excellent
              quality and the design choices are just perfect!"
            </p>
          </div>
          {/* Testimonial 4 */}
          <div className="bg-yellow-500 p-6 rounded-xl shadow-lg transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-2xl">
            <div className="flex items-center mb-4">
              <img
                src="https://img.freepik.com/free-photo/indian-man-portrait-temple_53876-14535.jpg?semt=ais_hybrid"
                alt="Mangal Singh"
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <p className="font-bold text-black">Mangal Singh</p>
                <p className="text-black">Happy Customer</p>
              </div>
            </div>
            <p className="text-xl mb-4 text-black drop-shadow-lg">
              "I am very impressed with the cards I purchased. Excellent
              quality and the design choices are just perfect!"
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
