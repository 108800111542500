import React from "react";

const Hero = () => {
  return (
    <div
      className="w-full h-screen bg-no-repeat bg-black "
      style={{
        backgroundImage: `url('/WhatsApp Image 2024-12-12 at 18.01.29_b1d07bf7.jpg')`,
        backgroundSize: "cover",
      }}
    >
      <div className="grid md:grid-cols-12 grid-cols-1 md:p-5">
        <div className=" col-span-6  ">
          <div className="md:w-[700px]  mt-10 md:mt-0 px-2 md:ml-5">
          <div className="">
          <h1 className="md:text-[6rem] text-[3rem]   text-white ">
              The Ultimate Destination for Premium <br />
              <div className="relative text-center ">
                <span className="uppercase font-impact md:text-[7rem] text-[4rem] font-bold text-yellow-400 ">
                  Playing Cards
                </span>
              </div>
            </h1>
          </div>
            <h2 className="md:text-4xl text-xl text-white">Get Top-Quality Playing Cards at Unbeatable Prices!</h2>
          </div>
    
          <button className="md:px-44 -ml-5 font-bold md:py-3 border-yellow-400 px-20 py-3 md:mt-5 border-[3px] absolute md:bottom-32 bottom-10 left-0 rounded-full uppercase text-white text-xl">
            Buy now
          </button>
        </div>
        <div className="col-span-6 relative ">
          <div className="w-full h-full">
            <img
              className="w-full h-full object-contain"
              src="/Group 1[1].png"
              alt=""
            />
            <img className="absolute md:-bottom-20 md:w-50 md:h-50 w-32 h-32 right-0" src="/Group 4[1].png" alt=""/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
